<template>
  <div class="users-list md:w-2/3 mx-auto fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="termsTrashTable"
                             :columns="columnsLabel"
                             :options="options"
                             @user:restore="restoreTerm($event)"
                             @user:deleted="$refs.deleteConfirmation.showDialog(), termDataForDelete = $event"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getTermsTrash()"
                             v-model="data"/>


    <custom-dialog ref="deleteConfirmation"
                   :title="$t('terms.confirmations.forceDelete.title')"
                   :body="$t('terms.confirmations.forceDelete.body', {name: termDataForDelete.name})"
                   @accept="deleteTerm(termDataForDelete)"
                   @close=""/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {checkUserPermissions, getAvatarUrl} from '../../../../assets/js/functions'
import InsertTerm from '../insert/insertTerm'
import EditTerm from '../edit/editTerm'
import TermProfile from '../profile/termProfile'
import CustomIcon from '../../../../components/customIcon/customIcon'
import {deleteTerm, getTermsTrash, restoreTerm} from "../../../../http/requests/terms";
import CustomDialog from "../../../../components/customDialog/customDialog";
import axios from "axios";

export default {
  name: 'TermsList',
  metaInfo () {
    return {
      title: this.$t('terms.trash.title')
    }
  },
  components: {
    CustomDialog,
    CustomIcon,
    TermProfile,
    EditTerm,
    InsertTerm,
    DraggableDynamicTable
  },
  data () {
    return {
      requestSent: false,
      insertNewTermPromptStatus: false,
      showTermPromptStatus: false,
      editUserPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'termsTrashTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'storeAction',
          i18n: 'courts.list.table.header.restore',
          color: 'success',
          width: '70px',
          minWidth: 70,
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'user:restore',
          action: {
            icon: 'icon-refresh-cw',
            iconPack: 'feather',
            color: 'success',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'deleteAction',
          i18n: 'courts.list.table.header.delete',
          color: 'danger',
          width: '70px',
          minWidth: 70,
          align: 'center',
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'user:deleted',
          action: {
            icon: 'icon-trash',
            iconPack: 'feather',
            color: 'danger',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'endTime',
          i18n: 'terms.list.table.header.endTime',
          width: 'calc((100% - 70px) / 4)',
          minWidth: 150,
          align: 'center',
          filter: false,
          filterType: 'date',
          sortable: true
          // locked: true
        },
        {
          field: 'startTime',
          i18n: 'terms.list.table.header.startTime',
          width: 'calc((100% - 70px) / 4)',
          minWidth: 150,
          align: 'center',
          filter: false,
          filterType: 'date',
          sortable: true
          // locked: true
        },
        {
          field: 'name',
          i18n: 'terms.list.table.header.name',
          width: 'calc((100% - 70px) / 4 * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: false,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'row',
          i18n: 'terms.list.table.header.row',
          width: '70px',
          minWidth: 70,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,asc'],
      termDataForDelete: {},
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
          ],
          leftToolbar: [
            // {
            //   id: 'printTable',
            //   // i18n: 'draggableDynamicTable.actions.print',
            //   icon: 'icon-printer',
            //   iconPack: 'feather'
            // },
            // {
            //   id: 'downloadTable',
            //   // i18n: 'draggableDynamicTable.actions.download',
            //   icon: 'icon-download',
            //   iconPack: 'feather'
            // },
            {
              id: {name: 'terms'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (!checkUserPermissions('term.forceDelete')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'deleteAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    if (!checkUserPermissions('term.restore')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'storeAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', ' md:w-2/3 mx-auto')
    }, 50)

    this.getTermsTrash()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getTermsTrash () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.termsTrashTable && this.data.length === 0) {
              this.$refs.termsTrashTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.termsTrashTable && this.data.length > 0) {
              this.$refs.termsTrashTable.loadMoreStatus = 'Loading'
            }

            getTermsTrash(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              res.data.data.forEach((term) => {

                this.data.push({
                  row: this.data.length + 1,
                  id: term.id,
                  registryDate: term.created_at,
                  gender: term.gender === 1 ? this.$t('genderTypes.man') : this.$t('genderTypes.woman'),
                  name: term.name || '',
                  startTime: term.start_time || '',
                  endTime: term.end_time || ''
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.termsTrashTable) this.$refs.termsTrashTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch(() => {
                if (this.$refs.termsTrashTable) this.$refs.termsTrashTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'name':
          if (filters[key].search !== '') filters_list.push(`full_name=${  filters[key].search}`)
          break

        case 'registryDate':
          if (filters[key].search !== '') filters_list.push(`created_at=${  filters[key].search}`)
          break
        }
      })
      filters_list.push('characters=1')

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getTermsTrash()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'startTime':
          sorts_list.push(`order[0]=start_time,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'endTime':
          sorts_list.push(`order[0]=start_time,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getTermsTrash()
    },
    reloadCourtsList () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getTermsTrash()
    },

    restoreTerm(court) {
      restoreTerm(court.id).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('terms.notifications.restore.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getTermsTrash()
      })
        .catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('terms.notifications.restore.error'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        })
    },
    deleteTerm(term) {
      deleteTerm(term.id, true).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('terms.notifications.delete.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getTermsTrash()
      })
        .catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 409:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('terms.notifications.delete.isUsedError'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 5000
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('terms.notifications.delete.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style scoped>
</style>
